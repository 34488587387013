import { Dialog, Transition } from '@headlessui/react'
import axios from 'axios'
import { Fragment } from 'react'
import { toast } from 'react-toastify'

export default function ConfirmModal({isOpen, setIsOpen , editor ,setISSubmited}) {

  function closeModal() {
    setIsOpen(false)
  }

  return (
    <>


      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-sm bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >Submit your drawing?
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                    Are you sure to submit your drawing? You will not be able to make changes after that.
                    </p>
                  </div>

                  <div className="mt-4 flex gap-2">
                  <button
                  onClick={closeModal}

                      type="button"
                      className="inline-flex justify-center rounded-sm border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 ">
                        Cancel
                      </button>
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-sm border border-transparent text-blue-100 px-4 py-2 text-sm font-medium bg-blue-900 "
                      onClick={ 
                        async () => { 
                            const resizedCanvas = document.createElement("canvas");
                            const resizedContext = resizedCanvas.getContext("2d");
          
                            resizedCanvas.height = "500";
                            resizedCanvas.width = "500";
          
                            resizedContext.drawImage(document.querySelector("canvas"), 0, 0, 500, 500);
                            const response = await fetch(resizedCanvas?.toDataURL());
                            const blob = await response.blob(); 
                            const file = new File(
                              [blob],
                              "fileName.png",
                              {
                                type: "image/png",
                                lastModified: new Date()
                              }
                            );
                            var formData = new FormData();
                            formData.append("file", file);
                            await axios.post("https://drawing.viziofly.com/LEDIMAGES/json/",formData ,{
                              withCredentials:true
                            }).then(
                              ()=>{
                                setISSubmited(true)
                              }
                            ).catch((err)=>{
                              toast.error(err.message)
                            })
                            closeModal()
                           }
                      }
                    >
                      Submit
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
