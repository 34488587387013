import { useContext, useEffect, useRef } from "react"
import { EditorContext } from "../../../editor"
import * as fabric from "fabric"
import { FiPenTool } from "react-icons/fi"
import { GiCircle, GiSpray } from "react-icons/gi"
import { FaEraser, FaMagic, FaPaintBrush, FaSprayCan } from "react-icons/fa"
import { HexColorPicker } from "react-colorful";
import { Popover } from '@headlessui/react'
import {MdFormatLineSpacing, MdLineWeight} from "react-icons/md"
const brushes = {
    pen: {
        Icon: FaPaintBrush,
        getBrush: (canvas) =>{
            if(!canvas.defaultBrush & canvas.freeDrawingBrush.name === undefined){
                canvas.defaultBrush = canvas.freeDrawingBrush
            }
            if(!canvas.defaultBrush  ){
                canvas.defaultBrush = new fabric.PencilBrush(canvas)
            }
         
           return  canvas.defaultBrush }
    },
  
    spray: {
        Icon: FaSprayCan,
        getBrush: (canvas) => new fabric.SprayBrush(canvas)
    },
    circle: {
        Icon: FaMagic,
        getBrush: (canvas) => new fabric.CircleBrush(canvas)
    },
    eraser: {
        Icon: FaEraser,
        getBrush: (canvas) => { 
            if(!canvas.defaultBrush & canvas.freeDrawingBrush.name === undefined){
                canvas.defaultBrush = canvas.freeDrawingBrush
            }
            if(!canvas.defaultBrush  ){
                canvas.defaultBrush = new fabric.PencilBrush(canvas)
            }
            const b =canvas.defaultBrush ; b.color = "#fff0"; return b }
    }
}
const BrushTools = () => {
    const toolsConfigRef = useRef({});
 
    const { editor, toolsConfig } = useContext(EditorContext)
    const canvas = editor?.canvas

    useEffect(() => {
        toolsConfigRef.current = toolsConfig
        if(!canvas) return
        let brush = canvas?.freeDrawingBrush
        console.log(brush.name)
        brush.color = toolsConfig?.current_color
        if (!(toolsConfig?.brushtool_type === "eraser") === false) {
            brush.color = "#f004"
        }
        brush.width = parseInt(toolsConfig?.brush_size , 10)
        if (!(toolsConfig?.active_tool === "brush") === false) {
            canvas.isDrawingMode = true;
        }
    }, [toolsConfig , canvas])
    
    const moveHandler = (event) => {
        if ((toolsConfigRef?.current?.active_tool === "brush") === false) {
            canvas.isDrawingMode = false;
            return
        }
        const { getBrush } = brushes[toolsConfigRef?.current?.brushtool_type] ?? brushes.pen
        const brush = getBrush(canvas)
        brush.color = toolsConfigRef?.current?.current_color
        if (!(toolsConfigRef?.current?.brushtool_type === "eraser") === false) {
            brush.color = "#f004"
        }
        canvas.isDrawingMode = true;
        brush.width = parseInt(toolsConfigRef?.current?.brush_size , 10)
        canvas.freeDrawingBrush = brush

    }
    const eraserPath =  (e) => {
        console.log(e)
        if ((toolsConfigRef?.current?.active_tool === "brush") === false) {
            canvas.isDrawingMode = false;
            return
        }
        if (!(toolsConfigRef?.current?.brushtool_type === "eraser") === false) {

            if (e.path) {
                console.log(e)
                // Set the path as selectable and evented
                e.path.selectable = false;
                e.path.evented = true;
                e.path.stroke = "#ffff"
                // Make the path transparent, effectively erasing it
                e.path.set({ globalCompositeOperation: "destination-out" });
                canvas.requestRenderAll();
            }
        }
    }

    useEffect(() => {

        if (!canvas) { return }

        canvas?.on("mouse:down", moveHandler);
        canvas.on('path:created', eraserPath );

        return () => {
            canvas.off("mouse:down", moveHandler)
            canvas.off("mouse:down", eraserPath)
        };
    })

    return <></>
}

const Button = () => {
    const { editor, toolsConfig, setToolsConfig } = useContext(EditorContext)
    const canvas = editor?.canvas
    console.log(toolsConfig?.active_tool)
    if (!canvas) return
    return <>
        <div className="border-gray-500 border-2 text-lg bg-white divide-x flex rounded"> {
            Object.keys(brushes).map(key => {
                const { Icon } = brushes[key] ? brushes[key] : FiPenTool
                return (<button
                    onClick={() => {
                        setToolsConfig({ ...toolsConfig, active_tool: "brush", brushtool_type: key });
                        
                        const { getBrush } = brushes[key] ?? brushes.pen
                        const brush = getBrush(canvas)
                        brush.color = toolsConfig?.current_color
                        if (!(key === "eraser") === false) {
                            brush.color = "#f004"
                        }
                        canvas.isDrawingMode = true;
                        brush.width = parseInt(toolsConfig?.brush_size , 10)
                        canvas.freeDrawingBrush = brush
                        canvas.requestRenderAll();
                    }}
                    key={key}
                    className={` hover:bg-gray-200 p-2 ${(toolsConfig?.active_tool === "brush" && toolsConfig?.brushtool_type === key) && "bg-blue-300"}`}>
                    <Icon />
                    {toolsConfig?.active_tool === "brush"}
                </button>


                )
            })
        }

        </div>

        <Popover className="relative border-2 flex items-center h-fit">
            <Popover.Button className={`rounded-sm hover:bg-gray-200 p-1 border-gray-500 border-2`}>
               <MdLineWeight className="w-6 h-6"/>
            </Popover.Button>

            <Popover.Panel className="absolute z-10 top-[100%] mt-2 w-32 bg-white shadow p-2 -left-[50%]">
               <input type="range"  onChange={(e)=>{setToolsConfig({...toolsConfig , brush_size:e.target.value}) ; }} value={toolsConfig?.brush_size} max={30} min={1}  className="w-full" />
            </Popover.Panel>
        </Popover>
        <Popover className="relative border-2 flex items-center h-fit">
            <Popover.Button className={`rounded-sm hover:bg-gray-200 p-1 border-gray-500 border-2`}>
                <div className="aspect-square w-6 border rounded-sm" style={{ backgroundColor: toolsConfig?.current_color }}></div>
            </Popover.Button>

            <Popover.Panel className="absolute z-10 top-[100%] mt-2 -left-[50%]">
                <HexColorPicker color={toolsConfig?.current_color} onChange={(c) => {  setToolsConfig({ ...toolsConfig, current_color: c }) }} />
            </Popover.Panel>
        </Popover>
    </>

}
BrushTools.Button = Button
export default BrushTools