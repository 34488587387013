import { useContext, useRef } from "react"
import { EditorContext } from "../../editor"
import { GrSelect } from "react-icons/gr"

const SelectControls = ()=><></>
const Button = () => {
    const { editor, toolsConfig , setToolsConfig } = useContext(EditorContext)
    const canvas = editor?.canvas
  
    
    return <>
    
    <button
        onClick={()=>{
            setToolsConfig({...toolsConfig, active_tool:"select" })
            if(canvas) {
                canvas.isDrawingMode = false
            }
        }}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${toolsConfig?.active_tool === "select"  && "bg-blue-300"}`}>
        <GrSelect />
    </button>
    </>
}
SelectControls.Button = Button
export default SelectControls