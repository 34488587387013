import { useContext, useRef } from "react"
import { EditorContext } from "../../editor"
import { FaRedoAlt, FaUndoAlt } from "react-icons/fa"

const HistoryControls = ()=><></>
const Button = () => {
    const { editor, toolsConfig , setToolsConfig } = useContext(EditorContext)
    const canvas = editor?.canvas
    const h = useRef([])
    function undo(){
        if(canvas._objects.length>0){
         h.current?.push(canvas._objects.pop());
         canvas.renderAll();
        }
      }
      function redo(){
        
        if(h.current.length>0){
         canvas.add(h.current.pop());
        }
      }
    return <>
    
    <button
        onClick={undo}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${canvas?.isDrawingMode && "bg-gray-100"}`}>
        <FaUndoAlt />
    </button>
    <button
        onClick={redo}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${canvas?.isDrawingMode && "bg-gray-100"}`}>
        <FaRedoAlt />
    </button></>
}
HistoryControls.Button = Button
export default HistoryControls