import { useContext, useEffect, useRef } from "react"
import { EditorContext } from "../../editor"
import { fabric } from "fabric"
import { BsPaintBucket } from "react-icons/bs"

const PenTool = () => {
    const toolsConfigRef = useRef({});

    const { editor , toolsConfig  } = useContext(EditorContext)
    const canvas = editor?.canvas
    const moveHandler = (options) => {
        if((toolsConfig?.active_tool === "bucket") === false){return }
        canvas.isDrawingMode = false; 
        canvas?.getActiveObject()?.set("fill",toolsConfigRef?.current?.current_color);
        canvas.renderAll()
    }
    useEffect(() => {
        toolsConfigRef.current = toolsConfig
    }, [toolsConfig])

    useEffect(() => {

        if (!canvas) { return }
        canvas?.on("selection:created", moveHandler);
        canvas?.on("selection:updated", moveHandler);
        
        return () => {
            canvas?.off("selection:created", moveHandler);
        canvas?.off("selection:updated", moveHandler);
        };
    })
    return <></>
}
const Button = () => {
    const { editor, toolsConfig , setToolsConfig } = useContext(EditorContext)
    const canvas = editor?.canvas
    return <button
        onClick={() => {
            if (!canvas) { return }
            setToolsConfig({...toolsConfig , active_tool:"bucket", bucket_tool:(!toolsConfig?.bucket_tool)})

        }}
        className={`rounded hover:bg-gray-200 border-2 border-gray-500 p-2 ${canvas?.isDrawingMode && "bg-gray-100"}`}>
        <BsPaintBucket className="text-lg"/>
    </button>
}
PenTool.Button = Button
export default PenTool