
import BrushTools from "./tools/brush"
import CreateTool from "./tools/create"
import DeleteTool from "./tools/delete"
import HistoryControls from "./tools/history"
import PenTool from "./tools/pentool"
import SelectControls from "./tools/select"
const ToolBar = () => {
    return <>
        <div className='grow flex-wrap bg-gray-100 h-fit flex justify-between'>
            <div className="flex gap-1 p-1 h-fit items-center text-gray-700">
             <CreateTool.Button></CreateTool.Button>
               <BrushTools/>
               <BrushTools.Button key={"brushtool"} />
               {/* <PenTool.Button/>
               <PenTool /> */}
               <SelectControls />
               <SelectControls.Button></SelectControls.Button>
               <DeleteTool.Button></DeleteTool.Button>
                <hr className="border-2 h-full"/>
               
            </div>
            <div className="flex items-center gap-2 p-2"><HistoryControls />
               <HistoryControls.Button ></HistoryControls.Button></div>
            
        </div></>
}

export default ToolBar