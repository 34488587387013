import { useEffect, useState } from 'react';
import './App.css';
import { FabricJSCanvas, useFabricJSEditor } from 'fabricjs-react'
import ToolBar from './components/toolbar';
import { EditorContext } from './components/editor';
import SuccessResponse from './components/success';
import ConfirmModal from './components/confirmModel';
import { toast } from 'react-toastify';



function App() {
  const { editor, onReady } = useFabricJSEditor()
  const [toolsConfig, setToolsConfig] = useState({ current_color: "#000", brush_size: 5, active_tool: "brush", brushtool_type: "pen" })
  const [isSubmited, setISSubmited] = useState(false)
  const [isSubmitConfirmOpen, setSubmitConfiemOpen] = useState(false)
  const updateCanvasSize = () => {
    const container = document.querySelector('.canvas-container');
    if (!container) {
      return
    }
    const size = Math.min(container.clientWidth, container.clientHeight);

    editor?.canvas?.setDimensions({
      width: size,
      height: size
    });
    if (!editor?.canvas) { return }

  }
  useEffect(() => {
    updateCanvasSize();
  })

  useEffect(() => {
    editor?.canvas?.renderAll()
    editor?.canvas?.requestRenderAll()
    const handler = (e) => {
      const v = e.target
      v.lockMovementX = true
      v.lockMovementY = true
      v.lockSkewingX = true
      v.lockSkewingY = true
      v.lockScalingX = true
      v.lockScalingY = true
    }
    editor?.canvas?.on("object:added", handler)

    editor?.canvas?.renderAll()
    return () => editor?.canvas?.off("object:added", handler)
  }, [editor?.canvas])

  return (
    <>
      {isSubmited ? <><SuccessResponse ></SuccessResponse> </> :
        <div style={{ height: "100dvh" }} className="w-screen overflow-y-auto overflow-hidden m-0 p-0 bg-gray-200">
          <EditorContext.Provider value={{ editor, toolsConfig, setToolsConfig }}>
            <div className='flex h-full flex-col'>
              <div className='shadow-lg border-b'>

                <ToolBar />
              </div>
              <div className='grow p-2 '><div className='h-full w-full flex canvas-container items-center justify-center overflow-y-auto'>
                <FabricJSCanvas className="bg-gray-50 aspect-square max-h-full max-w-full" onReady={onReady} />
              </div></div>
              <div className='p-2 bg-white sticky bottom-0'>
                <ConfirmModal isOpen={isSubmitConfirmOpen} setIsOpen={setSubmitConfiemOpen} setISSubmited={setISSubmited} editor={editor} />
                <button className='p-2 w-full bg-blue-800 font-semibold text-white rounded shadow' onClick={() => {
                  if (editor?.canvas?._objects.length <= 0) {
                    toast.error("Minimum of 1 stroke is required for submission.")
                    return
                  }
                  
                  setSubmitConfiemOpen(true)
                }}>Submit Drawing</button>
              </div>
            </div>
          </EditorContext.Provider>

        </div>
      }</>
  );
}

export default App;
