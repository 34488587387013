const SuccessResponse = () => {
    return <><div className="flex items-center justify-center " style={{height:"100dvh"}}>
        <div className="p-1 rounded w-full max-w-lg shadow-lg ">
            <div className="flex flex-col items-center p-4 space-y-2 bg-white">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="text-green-600 w-28 h-28"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={1}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                </svg>
                <h1 className="text-4xl font-bold font-extrabold ">
                Thank you!
                </h1>
                <p>
                Your drawing is submitted successfully.
                </p>
                <button onClick={()=>window.location.reload()} className="inline-flex items-center px-4 py-2 text-white bg-indigo-600 border border-indigo-600 rounded rounded-full hover:bg-indigo-700 focus:outline-none focus:ring">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 h-3 mr-2"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        strokeWidth={2}
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M7 16l-4-4m0 0l4-4m-4 4h18"
                        />
                    </svg>
                    <span className="text-sm font-medium">Create new drawing</span>
                </button>
            </div>
        </div>
    </div>
    </>
}
export default SuccessResponse